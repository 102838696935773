import React, { useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { Wrapper } from './Layout.styled';
import { useDispatch } from 'react-redux';
import { changeTheme } from '../../store/theme/theme.slice';

const Layout = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const mode = searchParams.get('theme');
  const isMainTheme = mode === 'main' || mode === null;

  useEffect(() => {
    if (isMainTheme) {
      dispatch(changeTheme({ mode: 'on' }));
    } else {
      dispatch(changeTheme({ mode: '' }));
    }
  }, []);

  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};

export default Layout;
