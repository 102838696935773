import React from 'react';
import {
  GreyRow,
  InfoDocBlock,
  LoaderWrapper,
  LoadingColorText,
  RightBlockStyled,
  Row,
  ScrollBlock,
  SubTitle,
  Title,
  VerifyPhotoBlock,
} from './RightBlock.styled';
import Divider from '../Divider/Divider';
import Badge from '../Badge/Badge';
import { useSelector } from 'react-redux';
import {
  dataVerifySelector,
  loadingVerifySelector,
  recognizeLoadingVerifySelector,
} from '../../store/verification/selectors';
import {
  getCertaintyToBadge,
  getDataRowIntoForBlock,
  getError,
  size,
} from '../../utils';
import Loader from '../Loader/Loader';
import { useMediaQuery } from '../../hooks';
import { livenessLang } from '../../utils/livenessLang';

const RightBlock = ({ currentMode }) => {
  const verificationData = useSelector(dataVerifySelector);

  const isLoading = useSelector(loadingVerifySelector);
  const isLoadingRecognize = useSelector(recognizeLoadingVerifySelector);

  const { selfieError, passportError } = getError(verificationData);
  const isMobile = useMediaQuery(size.mobile);

  const selfieData = verificationData.find(
    (obj) => obj.idDocSubType === 'SELFIE',
  );
  const passportData = verificationData.find(
    (obj) => obj.idDocSubType === 'FRONT_SIDE',
  );

  const dataArray = Object.values(passportData?.reviewResult?.fields || {});

  function search(arr, str) {
    return arr.find((obj) => obj?.title === str);
  }

  const newData = dataArray.filter(
    (obj) =>
      obj?.title !== 'Фамилия' &&
      obj?.title !== 'Имя' &&
      obj?.title !== 'Отчество',
  );

  const sortyData = [
    search(dataArray, 'Фамилия'),
    search(dataArray, 'Имя'),
    search(dataArray, 'Отчество'),
    ...newData,
  ];

  const isLiveness = selfieData?.reviewResult?.liveness === 'True';

  return (
    <RightBlockStyled>
      <VerifyPhotoBlock>
        <Title mode={currentMode}>Верификация фото</Title>
        <SubTitle>
          Совпадение:{' '}
          <LoadingColorText
            loader={verificationData.length || isLoading || isLoadingRecognize}
            mode={currentMode}
            weight={'weight'}
          >
            {getDataRowIntoForBlock(
              selfieData?.reviewResult?.confidence,
              isLoading,
              true,
            )}
          </LoadingColorText>
        </SubTitle>
        {selfieData?.reviewResult?.liveness && (
          <SubTitle>
            Liveness :{' '}
            <Badge
              mode={currentMode}
              certainty={isLiveness ? 'success' : 'error'}
              inline={true}
            >
              {livenessLang(isLiveness, currentMode)}
            </Badge>
          </SubTitle>
        )}
      </VerifyPhotoBlock>
      {!isMobile ? <Divider /> : null}
      <InfoDocBlock>
        <Title mode={currentMode}>Информация о документе</Title>
        <SubTitle mb={4}>
          Тип документа:{' '}
          <LoadingColorText
            loader={verificationData.length || isLoading || isLoadingRecognize}
            mode={currentMode}
            weight={'weight'}
          >
            {getDataRowIntoForBlock(passportData?.idDocType?.value, isLoading)}
          </LoadingColorText>
        </SubTitle>
        <SubTitle>
          Уверенность:{' '}
          <LoadingColorText
            loader={verificationData.length || isLoading || isLoadingRecognize}
            mode={currentMode}
            weight={'weight'}
          >
            {getDataRowIntoForBlock(
              passportData?.idDocType?.confidence,
              isLoading,
              true,
            )}
          </LoadingColorText>
        </SubTitle>
      </InfoDocBlock>
      <Divider />
      {/* * Паспортные данные */}
      {!selfieError && !passportError && passportData ? (
        <GreyRow>
          <span>ПОЛЕ</span>
          <span>ЗНАЧЕНИЕ</span>
          <span>УВЕРЕННОСТЬ</span>
        </GreyRow>
      ) : null}
      {isLoading || isLoadingRecognize ? (
        <LoaderWrapper>
          <Loader mode={currentMode} />
        </LoaderWrapper>
      ) : null}
      <ScrollBlock>
        {sortyData?.map((dataObject, index) => {
          if (!dataObject?.value) return;
          return (
            <Row key={index}>
              <span>{dataObject?.title}</span>
              <LoadingColorText>{dataObject?.value}</LoadingColorText>
              <Badge
                mode={currentMode}
                certainty={getCertaintyToBadge(dataObject?.confidence)}
              >
                {dataObject?.confidence}
              </Badge>
            </Row>
          );
        })}
      </ScrollBlock>
    </RightBlockStyled>
  );
};

export default RightBlock;
