import styled from 'styled-components';

export const LoaderStyled = styled.div`
  width: ${(props) => (props?.size ? `${props?.size}px` : '36px')};
  height: ${(props) => (props?.size ? `${props?.size}px` : '36px')};
  border: 5px solid;
  border-color: ${(props) => {
    if (props.mode !== undefined) {
      return props.mode === 'on' ? '#7364E7' : '#1e14dc';
    } else {
      return '#fff';
    }
  }};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
