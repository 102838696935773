import styled from 'styled-components';

export const BadgeStyled = styled.div`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  align-items: center;
  padding: 4px 12px;
  width: fit-content;
  height: 20px;
  background: ${(props) => {
    if (props?.certainty === 'high' && !props?.mode) return '#00B428';
    if (props?.certainty === 'high' && props?.mode) return '#7364E7';
    if (props?.certainty === 'middle') return '#2E5CFF';
    if (props?.certainty === 'low') return '#FFB23F';
    if (props?.certainty === 'error') return '#f10600';
    if (props?.certainty === 'success') return '#00B428';
  }};

  border-radius: 100px;
  text-transform: ${(props) => (props.inline ? 'initial' : 'capitalize')};
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #ffffff;
`;
