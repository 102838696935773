import { useState, useEffect } from 'react';

const SITE_ID = '6LeZIHgkAAAAAEM5J2i6UdgRGqT2PsUsAl5fi5a5';

export const useCaptcha = () => {
  const [isCaptchaReady, setCaptchaReady] = useState(false);
  const [triggerCaptcha, setTriggerCaptcha] = useState(false);
  let initWait;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_ID}`;
    script.async = true;
    script.onload = () => {
      window.grecaptcha.ready(() => {
        setCaptchaReady(true);
        initWait && initWait();
      });
    };
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, [triggerCaptcha]);

  const onExecute = (action) => {
    return new Promise((resolve) => {
      const exec = () =>
        resolve(window.grecaptcha.execute(SITE_ID, { action }));
      isCaptchaReady ? exec() : (initWait = () => exec());
    });
  };

  const hiddenCaptchaBadge = () => {
    const badge = document.querySelector('.grecaptcha-badge');
    if (badge) {
      badge.style.visibility = 'hidden';
    }
  };

  useEffect(() => {
    hiddenCaptchaBadge();
  }, [onExecute]);

  return {
    isCaptchaReady,
    onExecute,
    setTriggerCaptcha,
  };
};
