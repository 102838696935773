import axios from 'axios';

const api = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => Promise.reject(error),
);

export const verificationApi = (formData) => {
  return api.post('/verification', formData);
};

export const recognizeDocumentApi = (formData) => {
  return api.post('/recognize_document', formData);
};
