import styled from 'styled-components';

export const TitleBlockCSS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-family: RoadRadio, sans-serif;
  p {
    font-size: 21px;
  }
  p:last-of-type {
    font-size: 18px;
    color: ${(props) => (props.mode ? '#7364e7' : '#121aa8')};
  }
  margin-bottom: 50px;
`;

export const ButtonGroupCSS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  button {
    width: 100%;
  }
`;
