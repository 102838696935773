/* eslint-disable */
import React from 'react';
import {
  RemoveIcon,
  SaveIcon,
  SelfieAddIcon,
  UserPhotoAddIcon,
} from '../../components/Icons';
import ButtonCustom from '../../components/ButtonCustom/ButtonCustom';
import { Wrapper } from './DevPage.styled';
import styled from 'styled-components';
import Badge from '../../components/Badge/Badge';
import DoneChip from '../../components/DoneChip/DoneChip';
import RemoveIconButton from '../../components/RemoveIconButton/RemoveIconButton';
import Loader from '../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import { modeSelector } from '../../store/theme/selectors';

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const DevPage = () => {
  const currentMode = useSelector(modeSelector);
  const handleClick = () => {
    console.log('click');
  };

  return (
    <Wrapper>
      <Flex>
        <SaveIcon color={'black'} /> - SaveIcon
      </Flex>
      <Flex>
        <RemoveIcon /> - RemoveIcon
      </Flex>
      <Flex>
        <SelfieAddIcon /> - SelfieAddIcon
      </Flex>
      <Flex>
        <SelfieAddIcon mode /> - SelfieAddIcon mode on
      </Flex>
      <Flex>
        <UserPhotoAddIcon /> - UserPhotoAddIcon
      </Flex>
      <Flex>
        <UserPhotoAddIcon mode /> - UserPhotoAddIcon mode on
      </Flex>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>
      <Flex>
        <ButtonCustom onClick={handleClick} /> - ButtonCustom
      </Flex>
      <Flex>
        <ButtonCustom onClick={handleClick} mode={'mode'} /> - ButtonCustom
      </Flex>
      <Flex>
        <ButtonCustom onClick={handleClick} mode={'mode'} reset={'reset'} /> -
        ButtonCustom
      </Flex>
      <Flex>
        <ButtonCustom onClick={handleClick} disabled={'dis'} /> - ButtonCustom
      </Flex>
      <Flex>
        <Badge certainty={'high'}>Высокая</Badge> - Badge
        <Badge mode={'mode'} certainty={'high'}>
          Высокая
        </Badge>{' '}
        - Badge
        <Badge certainty={'middle'}>Средняя</Badge> - Badge
        <Badge certainty={'low'}>Низкая</Badge> - Badge
      </Flex>
      <Flex>
        <DoneChip />- DoneChip mode off
        <DoneChip mode={'asd'} /> - DoneChip mode on
      </Flex>
      <Flex>
        <RemoveIconButton onClick={handleClick} /> - RemoveIconButton
      </Flex>
      <Flex>
        <Loader /> - RemoveIconButton
      </Flex>

      <div>
        <div>test theme</div>
        <SelfieAddIcon mode={currentMode} />
        <ButtonCustom onClick={handleClick} mode={currentMode} />
        <Badge certainty={'high'} mode={currentMode}>
          {' '}
          Высокая{' '}
        </Badge>
        <DoneChip mode={currentMode} />
      </div>
    </Wrapper>
  );
};

export default DevPage;
