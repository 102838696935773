import styled from 'styled-components';
import { device } from '../../utils';

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 25px;

  @media ${device.mobile} {
    margin-top: auto;
    margin-bottom: ${(props) => (props?.iOS ? '50px' : 0)};
  }
  @media (max-width: 790px) {
    justify-content: space-between;
  }
  @media (max-width: 389px) {
    flex-wrap: wrap;
    gap: 10px;
  }
`;
