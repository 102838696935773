import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state;

export const loadingVerifySelector = createSelector(
  selectSelf,
  (state) => state.verificationSlice.loading,
);
export const recognizeLoadingVerifySelector = createSelector(
  selectSelf,
  (state) => state.verificationSlice.recognizeLoading,
);

export const dataVerifySelector = createSelector(
  selectSelf,
  (state) => state.verificationSlice.data,
);
