const SaveIcon = ({ size = '12', color = 'white' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 1.33333H10.6667L10.6667 9.448L9.448 10.6667H8.66667V8H2.66667L2.66667 10.6667H1.33333L1.33333 1.33333H2L2 6L10 6L10 1.33333ZM0.666667 12L10 12L11.8047 10.1953C11.9297 10.0703 12 9.9008 12 9.724L12 0.666666C12 0.489856 11.9298 0.320286 11.8047 0.195261C11.6797 0.0702372 11.5101 0 11.3333 0L0.666667 0C0.489856 0 0.320286 0.0702372 0.195262 0.195261C0.0702379 0.320286 0 0.489856 0 0.666666L0 11.3333C0 11.5101 0.0702379 11.6797 0.195262 11.8047C0.320286 11.9298 0.489856 12 0.666667 12ZM3.33333 4.66667L3.33333 1.33333L8.66667 1.33333V4.66667L3.33333 4.66667Z'
        fill={color}
      />
    </svg>
  );
};

export default SaveIcon;
