export const size = {
  mobile: 767,
  tablet: 1024,
};

export const device = {
  mobile: `(max-width: ${size.mobile}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
};

export const token = 'fceeb9bfe9b1d367bd6df8bfb7de0aaf5bf64dd5';

export const MODAL_NAME = {
  ATTENTION_MODAL: 'AttentionModal',
};
