import { createSlice } from '@reduxjs/toolkit';
import { recognizeDocumentThunk, verificationThunk } from './index';

const initialState = {
  data: [],
  error: null,
  loading: false,
  recognizeLoading: false,
};

const verification = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    nullableState: (state) => {
      state.data = [];
    },
    skipAllError: (state) => {
      const na = state.data.map((obj) => {
        return { ...obj, reviewResult: { ...obj.reviewResult, status: '' } };
      });
      state.data = na;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(verificationThunk.fulfilled, (state, action) => {
      state.data = action.payload.documentStatus;
      state.loading = false;
    });

    builder.addCase(verificationThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verificationThunk.rejected, (state) => {
      state.loading = false;
    });

    // ! document
    builder.addCase(recognizeDocumentThunk.fulfilled, (state, action) => {
      state.data = action.payload.documentStatus;
      state.recognizeLoading = false;
    });

    builder.addCase(recognizeDocumentThunk.pending, (state) => {
      state.recognizeLoading = true;
    });

    builder.addCase(recognizeDocumentThunk.rejected, (state) => {
      state.recognizeLoading = false;
    });
  },
});

export const { nullableState, skipAllError } = verification.actions;
export default verification.reducer;
