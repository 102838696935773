import styled, { css } from 'styled-components';
import { device } from '../../utils';

export const LeftBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  margin-right: 30px;
  padding: 16px;
  border-radius: 24px;
  background: #ffffff;
  min-width: 730px;
  @media (max-width: 1280px) {
    min-width: auto;
  }
  @media ${device.tablet} {
    margin-right: 16px;
  }
  @media ${device.mobile} {
    margin-right: 0;
    min-height: 100vh;
    justify-content: start;
    padding-bottom: 42px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  @media ${device.tablet} {
    margin-bottom: 16px;
  }
`;

export const TitleBlock = styled.div`
  display: flex;
  > div {
    width: 50%;
  }
  > div:first-of-type {
    margin-right: 30px;
  }
  margin-bottom: 6px;
  @media ${device.tablet} {
    margin-bottom: 8px;
    > div:first-of-type {
      margin-right: 16px;
    }
  }
`;

export const SubTitleBlock = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #151515;
  @media ${device.tablet} {
    line-height: 14px;
  }
`;

export const DropzoneBlockWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 180px;
  margin-bottom: 16px;

  & > div:first-of-type {
    margin-right: 30px;
  }
  @media ${device.tablet} {
    height: 112px;
    & > div:first-of-type {
      margin-right: 16px;
    }
  }
  @media ${device.mobile} {
    height: 130px;
    & > div:first-of-type {
      margin-right: 16px;
    }
  }
`;

export const TitleWrapperBottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  @media ${device.mobile} {
    margin-bottom: 8px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  //line-height: 28px;
  font-style: normal;
  color: #151515;
  ${(props) => {
    if (props?.mode) {
      return css`
        font-family: RoadRadio, sans-serif;
        font-weight: 400;
      `;
    } else {
      return css`
        font-family: Roboto, sans-serif;
        font-weight: 500;
      `;
    }
  }}
`;

export const SubTitleText = styled.div`
  display: flex;

  span {
    width: 50%;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #b5b5b5;
  }
`;

export const ImageBlockWrapper = styled.div`
  display: flex;
  height: 270px;
  margin-bottom: 16px;

  & > div:first-of-type {
    margin-right: 30px;
  }
  @media ${device.tablet} {
    height: 230px;
    & > div:first-of-type {
      margin-right: 15px;
    }
  }
  @media ${device.mobile} {
    height: 200px;
  }
`;

export const ImageBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 8px;
  grid-template-areas:
    '. .'
    '. .'
    '. .';
`;

export const ImageItem = styled.div`
  position: relative;
  background: ${(props) => {
    if (props.mode && props.target) return '#7364E7';
    if (!props.mode && props.target)
      return 'linear-gradient(90deg, #250E49 0%, #121AA8 76.04%, #0852A5 100%)';
  }};
  box-shadow: 0px 4px 12px -2px rgba(21, 21, 21, 0.1);
  border-radius: 8px;
  padding: 4px;
  height: 84px;
  @media ${device.tablet} {
    height: 71px;
  }
  @media ${device.mobile} {
    height: 60px;
    padding: 2px;
  }
`;

export const ImageUnit = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #ffffff;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const DoneChipStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: ${(props) =>
    props.mode
      ? '#7364E7'
      : 'linear-gradient(90deg, #250E49 0%, #121AA8 76.04%, #0852A5 100%)'};
`;

// export const Modal = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: fixed;
//   background: rgba(124, 124, 124, 0.65);
//   inset: 0;
// `;
