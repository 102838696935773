import React, { useEffect } from 'react';
import ButtonCustom from '../../ButtonCustom/ButtonCustom';
import { useSelector } from 'react-redux';
import { modeSelector } from '../../../store/theme/selectors';
import { ButtonGroupCSS, TitleBlockCSS } from './AttentionModal.styled';
import { useModalContext } from '../../../context/ModalContext';

export const AttentionModal = () => {
  const { onCloseModal, openModal } = useModalContext();
  const currentMode = useSelector(modeSelector);

  const handleClick = async () => {
    openModal.content.funkAgree(true);
  };

  useEffect(() => {
    return () => openModal.content.funkAgree(false);
  }, []);

  return (
    <div>
      <TitleBlockCSS mode={currentMode}>
        <p>
          При нажатии &quot;Продолжить&quot; Вы перенаправитесь на процедуру
          фотографирования лица.
        </p>
        <p>При необходимости предоставте доступ к камере вашего устройства.</p>
      </TitleBlockCSS>
      <ButtonGroupCSS>
        <ButtonCustom onClick={onCloseModal} offIcon={true} reset={'true'}>
          Отмена
        </ButtonCustom>
        <ButtonCustom mode={currentMode} onClick={handleClick} offIcon={true}>
          Продолжить
        </ButtonCustom>
      </ButtonGroupCSS>
    </div>
  );
};
