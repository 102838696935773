import React from 'react';
import { DoneChipStyled } from './DoneChip.styled';
import { DoneIcon } from '../Icons';

const DoneChip = ({ mode }) => {
  return (
    <DoneChipStyled mode={mode}>
      <DoneIcon />
    </DoneChipStyled>
  );
};

export default DoneChip;
