import React, { useState } from 'react';
import { ModalContent } from '../components/Modal/ModalContent/ModalContent';

export const ModalContext = React.createContext({
  onOpenModal: () => {},
  onCloseModal: () => {},
  openModal: {
    name: '',
    content: {},
    open: false,
  },
});

export const useModalContext = () => {
  const modalContext = React.useContext(ModalContext);

  if (!modalContext) {
    throw new Error(
      'useModalContext must be used within a ModalContextProvider',
    );
  }
  return modalContext;
};

export const ModalProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState({
    name: '',
    content: {},
    open: false,
  });

  const onOpenModal = (name, content) => {
    setOpenModal({ name, content, open: true });
  };

  const onCloseModal = () => {
    setOpenModal({ name: '', content: {}, open: false });
  };

  const value = {
    openModal,
    onOpenModal,
    onCloseModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      <ModalContent />
    </ModalContext.Provider>
  );
};
