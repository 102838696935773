import React from 'react';
import PropTypes from 'prop-types';
import { BadgeStyled } from './Badge.styled';

const Badge = ({ mode, certainty, children, inline = false }) => {
  return (
    <BadgeStyled mode={mode} certainty={certainty} inline={inline}>
      {children}
    </BadgeStyled>
  );
};

export default Badge;

Badge.propTypes = {
  mode: PropTypes.string,
  certainty: PropTypes.oneOf(['high', 'middle', 'low', 'error', 'success']),
  children: PropTypes.node,
  inline: PropTypes.bool,
};
