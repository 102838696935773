import React from 'react';

const RemoveIcon = ({ size = '16', color = '#F23333' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.3333 5.33325H2.66663V13.9999C2.66663 14.1767 2.73686 14.3463 2.86189 14.4713C2.98691 14.5963 3.15648 14.6666 3.33329 14.6666H12.6666C12.8434 14.6666 13.013 14.5963 13.138 14.4713C13.2631 14.3463 13.3333 14.1767 13.3333 13.9999V5.33325ZM12 6.66659V13.3333H3.99996V6.66659H12ZM9.99996 7.99992H8.66663V11.9999H9.99996V7.99992ZM7.33329 7.99992H5.99996V11.9999H7.33329V7.99992ZM11.3333 3.33325V1.99992C11.3333 1.82311 11.2631 1.65354 11.138 1.52851C11.013 1.40349 10.8434 1.33325 10.6666 1.33325H5.33329C5.15648 1.33325 4.98691 1.40349 4.86189 1.52851C4.73686 1.65354 4.66663 1.82311 4.66663 1.99992V3.33325H1.33329V4.66659H14.6666V3.33325H11.3333ZM9.99996 2.66659V3.33325H5.99996V2.66659H9.99996Z'
        fill={color}
      />
    </svg>
  );
};

export default RemoveIcon;
