import React from 'react';
import { SaveIcon } from '../Icons';
import { ButtonCustomWrapper } from './ButtonCustom.styled';
import PropTypes from 'prop-types';

const ButtonCustom = ({
  mode,
  reset,
  disabled,
  onClick,
  children = 'Button',
  offIcon = false,
}) => {
  return (
    <ButtonCustomWrapper
      mode={mode}
      disabled={disabled}
      reset={reset}
      onClick={onClick}
      offIcon={offIcon}
    >
      <span>{children}</span>
      {!offIcon && <SaveIcon />}
    </ButtonCustomWrapper>
  );
};

export default ButtonCustom;

ButtonCustom.propTypes = {
  mode: PropTypes.string,
  reset: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  offIcon: PropTypes.bool,
};
