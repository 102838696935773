import Modal from 'react-modal';
import React from 'react';
import { useModalContext } from '../../../context/ModalContext';
import { AttentionModal } from '../AttentionModal/AttentionModal';
import { MODAL_NAME, size } from '../../../utils/constants';
import { useMediaQuery } from '../../../hooks/index';

export const ModalContent = () => {
  const { openModal, onCloseModal } = useModalContext();
  const isMobile = useMediaQuery(size.mobile);

  const customStyles = {
    content: {
      top: !isMobile ? '50%' : '16px',
      left: !isMobile ? '50%' : '16px',
      right: !isMobile ? 'auto' : '16px',
      bottom: !isMobile ? 'auto' : '16px',
      marginRight: !isMobile && '-50%',
      transform: !isMobile ? 'translate(-50%, -50%)' : 'translateY(50%)',
      maxWidth: !isMobile ? '420px' : '100%',
      height: 'fit-content',
    },
    overlay: {
      zIndex: 10,
      background: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  return (
    <div>
      <Modal
        isOpen={openModal.open}
        onRequestClose={onCloseModal}
        style={customStyles}
        contentLabel='Example Modal'
        ariaHideApp={false}
      >
        <TypeModal />
      </Modal>
    </div>
  );
};

const TypeModal = () => {
  const { openModal } = useModalContext();
  if (!openModal.name) return;
  switch (openModal.name) {
    case MODAL_NAME.ATTENTION_MODAL:
      return <AttentionModal />;
    default:
      return <div />;
  }
};
