import React from 'react';
import { RemoveIcon } from '../Icons';
import { RemoveIconButtonStyled } from './RemoveIconButton.styled';
import PropTypes from 'prop-types';

const RemoveIconButton = ({ onClick }) => {
  return (
    <RemoveIconButtonStyled onClick={onClick}>
      <RemoveIcon />
    </RemoveIconButtonStyled>
  );
};

export default RemoveIconButton;

RemoveIconButton.propTypes = {
  onClick: PropTypes.func,
};
