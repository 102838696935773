import ButtonCustom from '../ButtonCustom/ButtonCustom';
import React from 'react';
import { ButtonBlock } from './ButtonGroup.styled';

const ButtonGroup = ({
  isEmptyVerificationData,
  previewPassport,
  previewPhoto,
  isLoading,
  currentMode,
  handleFetch,
  handleReset,
  handleFetchOnlyPhoto,
  isLoadingRecognize,
}) => {
  function iOS() {
    return /iPad|iPhone|iPod/.test(navigator?.userAgent);
  }
  return (
    <ButtonBlock iOS={iOS()}>
      {!isEmptyVerificationData ? (
        <>
          <ButtonCustom
            mode={currentMode}
            onClick={handleFetchOnlyPhoto}
            disabled={!previewPassport || isLoading || isLoadingRecognize}
          >
            Распознать документ
          </ButtonCustom>
          <ButtonCustom
            disabled={
              !(previewPassport && previewPhoto) ||
              isLoading ||
              isLoadingRecognize
            }
            mode={currentMode}
            onClick={handleFetch}
          >
            Верифицировать
          </ButtonCustom>
        </>
      ) : (
        <ButtonCustom reset={'reset'} onClick={handleReset}>
          Сбросить
        </ButtonCustom>
      )}
    </ButtonBlock>
  );
};

export default ButtonGroup;
