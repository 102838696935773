import React from 'react';
import LeftBlock from '../../components/LeftBlock/LeftBlock';
import RightBlock from '../../components/RightBlock/RightBlock';
import { MainBlock, Wrapper } from './KycPage.styled';
import { useSelector } from 'react-redux';
import { modeSelector } from '../../store/theme/selectors';
import { useMediaQuery } from '../../hooks';
import { size } from '../../utils';

const KycPage = () => {
  const currentMode = useSelector(modeSelector);
  const isMobile = useMediaQuery(size.mobile);

  return (
    <Wrapper>
      <MainBlock>
        <LeftBlock currentMode={currentMode} />
        {!isMobile ? <RightBlock currentMode={currentMode} /> : null}
      </MainBlock>
    </Wrapper>
  );
};

export default KycPage;
