import {
  recognizeDocumentApi,
  verificationApi,
} from '../../service/network/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const verificationThunk = createAsyncThunk(
  'verification/verificationThunk',
  async ({ formData }, { rejectWithValue }) => {
    try {
      return await verificationApi(formData);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const recognizeDocumentThunk = createAsyncThunk(
  'verification/recognizeDocumentThunk',
  async ({ formData }, { rejectWithValue }) => {
    try {
      return await recognizeDocumentApi(formData);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
