import React from 'react';

const DoneIcon = ({ size = '17', color = 'white' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.16668 10.7813L13.2947 4.65265L14.238 5.59532L7.16668 12.6666L2.92401 8.42398L3.86668 7.48132L7.16668 10.7813Z'
        fill={color}
      />
    </svg>
  );
};

export default DoneIcon;
