import styled from 'styled-components';
import { device } from '../../utils';

export const ButtonCustomWrapper = styled.button`
  padding: 15px 23px;
  border-radius: 8px;
  justify-content: center;
  background: ${(props) => {
    if (props.disabled) {
      return '#EAEAEA';
    }
    if (props?.reset) {
      return '#151515';
    }
    if (props?.mode) {
      return '#7364E7';
    } else {
      return 'linear-gradient(90deg, #250e49 0%, #121aa8 76.04%, #0852a5 100%)';
    }
  }};

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${(props) => (props.disabled ? '#B5B5B5' : '#ffffff')};

  span {
    margin-right: ${(props) => (props.offIcon ? '' : '10px')};
  }

  svg {
    path {
      fill: ${(props) => (props.disabled ? '#B5B5B5' : '#ffffff')};
    }
  }

  &:hover {
    opacity: ${(props) => (props.disabled ? 1 : 0.9)};
  }
  &:active {
    opacity: ${(props) => (props.disabled ? 1 : 0.8)};
  }
  @media ${device.tablet} {
    padding: 15px 12px;
  }
  @media ${device.mobile} {
    width: 100%;
  }
  @media (max-width: 790px) {
    span {
      margin-right: 0;
    }
    svg {
      display: none;
    }
  }
`;
