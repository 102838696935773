import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 16px 85px;
  background: #f5f5f5;
  @media (max-width: 1279px) {
    padding: 16px;
  }
  @media ${device.mobile} {
    padding: 0;
  }
`;

export const MainBlock = styled.div`
  display: flex;
  max-width: 1110px;
  margin: 0 auto;
`;
