import styled, { css } from 'styled-components';
import { RemoveIconButtonStyled } from '../RemoveIconButton/RemoveIconButton.styled';
import { device } from '../../utils';

export const DropzoneWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${(props) => (props?.mode ? '#F3F3F3' : '#dbe8ff')};
  border: 4px solid;
  border-color: ${(props) => {
    if (props.error) {
      return '#FF3535';
    } else {
      return props?.mode ? '#EBEBEB' : '#b5bdf1';
    }
  }};
  border-radius: 12px;
`;

export const TargetBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ActionButton = styled.div`
  display: ${(props) => (props?.disableActionButton ? 'none' : 'flex')};
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  //border: 1px solid red;
  left: 50%;
  top: 9px;
  transform: translateX(-50%);
  cursor: pointer;
  transition: 0.2s;
  :hover {
    box-shadow: 0px 5px 16px rgba(50, 56, 110, 1);
    transition: 0.2s;
  }

  @media ${device?.tablet} {
    top: 0;
    width: 40px;
    height: 40px;
  }
  @media ${device?.mobile} {
    top: 21px;
    width: 44px;
    height: 44px;
  }
`;

export const CenterIconBlock = styled.div`
  display: flex;
  margin-bottom: 16px;

  @media ${device?.tablet} {
    margin-bottom: 8px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #151515;

  text-align: center;
  margin-bottom: 16px;
  font-family: ${(props) =>
    props?.mode ? 'RoadRadio, sans-serif' : 'Roboto, sans-serif'};
  font-weight: ${(props) => (props?.mode ? 700 : 600)};
  @media ${device?.tablet} {
    font-size: 12px;
    margin-bottom: 2px;
    max-width: 148px;
  }
  @media ${device?.mobile} {
    font-size: 12px;
    margin-bottom: 0;
  }
`;

export const SubTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #808080;
  span {
    ${(props) => {
      if (props?.mode) {
        return css`
          color: #7364e7;
        `;
      } else {
        return css`
          background: linear-gradient(
            90deg,
            #250e49 0%,
            #121aa8 76.04%,
            #0852a5 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
        `;
      }
    }}
  }
  @media ${device.tablet} {
    font-size: 10px;
    line-height: 12px;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

export const DeleteButton = styled(RemoveIconButtonStyled)`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

export const ImageBlock = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  height: inherit;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const LoaderBlock = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    0deg,
    rgba(21, 21, 21, 0.65),
    rgba(21, 21, 21, 0.65)
  );
  border-radius: 8px;
`;

//! -------- ERROR BLOCK DROPZONE -------- start

export const ErrorBlock = styled.div`
  position: absolute;
  left: 6px;
  right: 6px;
  top: 6px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 116px;
  @media ${device.tablet} {
    border-radius: 6px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
  }
`;

export const ErrorBlockContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Blur = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  width: 100%;
  border-radius: 12px;
  top: 0;
  bottom: 0;
`;

export const TittleError = styled.div`
  font-family: ${(props) =>
    props?.mode ? 'RoadRadio, sans-serif' : 'Roboto, sans-serif'};
  font-style: normal;
  font-weight: ${(props) => (props?.mode ? 400 : 500)};
  font-size: 16px;
  line-height: 16px;
  color: #151515;
  margin-bottom: 8px;
`;

export const SubTitleError = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #151515;
  text-align: center;
`;

//! -------- ERROR BLOCK DROPZONE -------- end
