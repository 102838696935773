export default (str) => {
  switch (str) {
    case 'высокая':
      return 'high';
    case 'средняя':
      return 'middle';
    case 'низкая':
      return 'low';
  }
};
