import React, { useEffect, useState } from 'react';
import {
  DoneChipStyled,
  DropzoneBlockWrapper,
  ImageBlock,
  ImageBlockWrapper,
  ImageItem,
  ImageUnit,
  LeftBlockStyled,
  SubTitleBlock,
  SubTitleText,
  Title,
  TitleBlock,
  TitleWrapper,
  TitleWrapperBottom,
} from './LeftBlock.styled';
import { DropzoneBlock } from '../DropzoneBlock/DropzoneBlock';
import { DoneIcon, SelfieAddIcon, UserPhotoAddIcon } from '../Icons';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  recognizeDocumentThunk,
  verificationThunk,
} from '../../store/verification';
import {
  dataVerifySelector,
  loadingVerifySelector,
  recognizeLoadingVerifySelector,
} from '../../store/verification/selectors';
import { nullableState } from '../../store/verification/verification.slice';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import { useMediaQuery } from '../../hooks';
import { size } from '../../utils';
import RightBlock from '../RightBlock/RightBlock';
import { useCaptcha } from '../../hooks/useCaptcha';
import { useModalContext } from '../../context/ModalContext';

const passportsArray = [
  { id: '1', name: 'photo1.png', url: './images/passport/photo1.png' },
  { id: '2', name: 'photo2.png', url: './images/passport/photo2.png' },
  { id: '5', name: 'photo3.png', url: './images/passport/photo5.png' },
  { id: '3', name: 'photo4.png', url: './images/passport/photo3.png' },
  { id: '6', name: 'photo5.png', url: './images/passport/photo6.png' },
  { id: '4', name: 'photo6.png', url: './images/passport/photo4.png' },
];

const photosArray = [
  { id: '1', name: 'photo1.png', url: './images/photo/photo1.png' },
  { id: '2', name: 'photo2.png', url: './images/photo/photo2.png' },
  { id: '3', name: 'photo3.png', url: './images/photo/photo3.png' },
  { id: '4', name: 'photo4.png', url: './images/photo/photo4.png' },
  { id: '5', name: 'photo5.png', url: './images/photo/photo5.png' },
  { id: '6', name: 'photo6.png', url: './images/photo/photo6.png' },
];

const LeftBlock = ({ currentMode }) => {
  const [previewPassport, setPreviewPassport] = useState();
  const [previewPhoto, setPreviewPhoto] = useState();

  const dispatch = useDispatch();
  const isMobile = useMediaQuery(size.mobile);
  const { onExecute, setTriggerCaptcha } = useCaptcha();

  const isLoading = useSelector(loadingVerifySelector);
  const isLoadingRecognize = useSelector(recognizeLoadingVerifySelector);

  const verificationData = useSelector(dataVerifySelector);
  const isEmptyVerificationData = verificationData.length;

  const selfie =
    verificationData &&
    verificationData.find((data) => data?.idDocSubType === 'SELFIE')
      ?.reviewResult;

  const passport =
    verificationData &&
    verificationData.find((data) => data?.idDocSubType === 'FRONT_SIDE')
      ?.reviewResult;

  const handleClickSetPhoto = async (e, path, name = '', field) => {
    if (isLoading) return;
    const blob = await fetch(path).then((res) => res.blob());
    let file = new File([blob], name, {
      type: 'image.png',
    });
    const newObj = Object.assign(file, {
      preview: path,
    });
    if (field === 'passport') {
      if (previewPassport?.name === name) return handleRemove(field);
      if (previewPassport) dispatch(nullableState());
      setPreviewPassport(newObj);
    } else {
      if (previewPhoto?.name === name) return handleRemove(field);
      if (previewPhoto) dispatch(nullableState());
      setPreviewPhoto(newObj);
    }
  };

  const onDropPassport = (acceptedFiles) => {
    setPreviewPassport(
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      }),
    );
  };

  const onDropPhoto = (acceptedFiles) => {
    setPreviewPhoto(
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      }),
    );
  };

  const {
    getRootProps: getRootPropsPassport,
    getInputProps: getInputPropsPassport,
  } = useDropzone({ onDrop: onDropPassport });

  const { getRootProps: getRootPropsPhoto, getInputProps: getInputPropsPhoto } =
    useDropzone({ onDrop: onDropPhoto });

  const handleFetch = async () => {
    setTriggerCaptcha((prevState) => !prevState);
    const captcha = await onExecute('redeemCode');
    if (!captcha) return;
    let formData = new FormData();
    formData.append('token', captcha);
    formData.append('selfie', previewPhoto);
    formData.append('front_side', previewPassport);

    dispatch(verificationThunk({ formData }));
  };

  function handleRemove(field) {
    dispatch(nullableState());
    if (field === 'passport') {
      setPreviewPassport(null);
    } else {
      setPreviewPhoto(null);
    }
  }

  const handleReset = () => {
    dispatch(nullableState());
    setPreviewPhoto(null);
    setPreviewPassport(null);
  };

  function getIsMobileAndIsData() {
    return isMobile && !!isEmptyVerificationData;
  }
  const isMobileAndIsData = getIsMobileAndIsData();

  const handleFetchOnlyPhoto = async () => {
    setTriggerCaptcha((prevState) => !prevState);
    const captcha = await onExecute('redeemCode');

    if (!captcha) return;
    if (previewPhoto) {
      setPreviewPhoto(null);
    }
    let formData = new FormData();
    formData.append('token', captcha);
    formData.append('front_side', previewPassport);

    dispatch(recognizeDocumentThunk({ formData }));
  };

  // !NOT DELETE AT THE REQUEST OF THE CUSTOMER
  // console.log(JSON.stringify(verificationData, null, 4));

  return (
    <LeftBlockStyled>
      <TitleWrapper>
        <TitleBlock>
          <Title mode={currentMode}>Документы</Title>
          <Title mode={currentMode}>Селфи</Title>
        </TitleBlock>
        <SubTitleBlock>
          Для верификации загрузите фото документа и селфи после чего нижмите на
          кнопку “Верифицировать”
        </SubTitleBlock>
      </TitleWrapper>
      <DropzoneBlockWrapper>
        <DropzoneBlock
          handleRemove={() => handleRemove('passport')}
          error={passport?.status === 'failed' ? passport : ''}
          loader={isLoading || isLoadingRecognize}
          getRootProps={getRootPropsPassport}
          getInputProps={getInputPropsPassport}
          preview={previewPassport}
          onDrop={onDropPassport}
          mode={currentMode}
          text={'загрузите фото документа'}
          logoIcon={<SelfieAddIcon mode={currentMode} />}
          disableActionButton={true} // отключить ховер при наведении на кнопку, так как на данный момент функционал стандартный, none SDK
          // actionButtonClick={requestPermission}
        />
        <DropzoneBlock
          error={selfie?.status === 'failed' ? selfie : ''}
          loader={isLoading}
          getRootProps={getRootPropsPhoto}
          getInputProps={getInputPropsPhoto}
          preview={previewPhoto}
          handleRemove={() => handleRemove('selfie')}
          onDrop={onDropPhoto}
          mode={currentMode}
          // text={'загрузите селфи'}
          text={'Загрузите селфи или селфи с документом'}
          logoIcon={<UserPhotoAddIcon mode={currentMode} />}
          disableActionButton={true}
        />
      </DropzoneBlockWrapper>
      {isMobileAndIsData ? (
        <RightBlock currentMode={currentMode} />
      ) : (
        <>
          <TitleWrapperBottom>
            <TitleBlock>
              <Title mode={currentMode}>Шаблоны</Title>
            </TitleBlock>
            <SubTitleText>
              <span>ДОКУМЕНТ</span>
              <span>СЕЛФИ</span>
            </SubTitleText>
          </TitleWrapperBottom>
          <ImageBlockWrapper>
            <ImageBlock>
              {passportsArray
                ? passportsArray.map((passport) => {
                    const isTarget = previewPassport?.name === passport.name;
                    return (
                      <ImageItem
                        key={passport.id}
                        mode={currentMode}
                        target={isTarget ? 'target' : ''}
                      >
                        <ImageUnit>
                          <img
                            role={'presentation'}
                            id={passport.id}
                            src={passport.url}
                            alt={'pas'}
                            onClick={(e) =>
                              handleClickSetPhoto(
                                e,
                                passport.url,
                                passport.name,
                                'passport',
                              )
                            }
                          />
                        </ImageUnit>
                        {isTarget ? (
                          <DoneChipStyled mode={currentMode}>
                            <DoneIcon />
                          </DoneChipStyled>
                        ) : null}
                      </ImageItem>
                    );
                  })
                : null}
            </ImageBlock>
            <ImageBlock>
              {photosArray
                ? photosArray.map((photo) => {
                    const isTarget = previewPhoto?.name === photo.name;
                    return (
                      <ImageItem
                        key={photo.id}
                        mode={currentMode}
                        target={isTarget ? 'target' : ''}
                      >
                        <ImageUnit
                          id={photo.id}
                          onClick={(e) =>
                            handleClickSetPhoto(
                              e,
                              photo.url,
                              photo.name,
                              'selfie',
                            )
                          }
                        >
                          <img src={photo.url} alt={'i'} />
                        </ImageUnit>
                        {isTarget ? (
                          <DoneChipStyled mode={currentMode}>
                            <DoneIcon />
                          </DoneChipStyled>
                        ) : null}
                      </ImageItem>
                    );
                  })
                : null}
            </ImageBlock>
          </ImageBlockWrapper>
        </>
      )}
      <ButtonGroup
        currentMode={currentMode}
        isEmptyVerificationData={isEmptyVerificationData}
        handleFetch={handleFetch}
        handleReset={handleReset}
        handleFetchOnlyPhoto={handleFetchOnlyPhoto}
        isLoading={isLoading}
        isLoadingRecognize={isLoadingRecognize}
        previewPassport={previewPassport}
        previewPhoto={previewPhoto}
      />
    </LeftBlockStyled>
  );
};

export default LeftBlock;
