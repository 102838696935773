import styled from 'styled-components';

export const DoneChipStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: ${(props) =>
    props.mode
      ? '#7364E7'
      : 'linear-gradient(90deg, #250E49 0%, #121AA8 76.04%, #0852A5 100%)'};
`;
