import styled, { css } from 'styled-components';
import { device } from '../../utils';

export const RightBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  padding: 16px;
  border-radius: 24px;
  background: #ffffff;

  width: 350px;
  @media ${device.tablet} {
    width: 343px;
  }
  @media ${device.mobile} {
    padding: 0;
    width: auto;
    margin-bottom: 16px;
  }
`;

export const VerifyPhotoBlock = styled.div`
  color: #151515;
  font-style: normal;
`;

export const Title = styled.div`
  font-family: ${(props) =>
    props?.mode ? 'RoadRadio, sans-serif' : 'Roboto, sans-serif'};
  font-weight: ${(props) => (props?.mode ? 400 : 500)};
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @media ${device.mobile} {
    font-weight: 600;
    margin-bottom: 8px;
  }
`;

export const SubTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  span {
    font-weight: 700;
  }
  margin-bottom: ${(props) => (props?.mb ? `${props.mb}px` : '16px')};
`;

export const InfoDocBlock = styled.div`
  margin-top: 16px;
  @media ${device.mobile} {
    margin-top: 0;
  }
`;

export const GreyRow = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #b5b5b5;
  span {
    width: 110px;
    display: inline-flex;
  }
  span:last-of-type {
    justify-content: end;
    margin-left: auto;
  }
  margin: 16px 0;
`;

export const Row = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #151515;
  margin-bottom: 16px;
  & span:first-of-type {
    width: 110px;
    display: inline-flex;
  }
  & div:last-of-type {
    margin-left: auto;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const LoadingColorText = styled.span`
  max-width: 136px;
  overflow-wrap: anywhere;
  font-weight: ${(props) => {
    if (props.weight) return 700;
    if (!props.weigth) {
      return props.loader ? 500 : 400;
    }
  }};
  font-size: ${(props) => {
    if (props.weight) return '14px';
    if (!props.weight) {
      return props?.loader ? '14px' : '12px';
    }
  }};
  line-height: 18px;
  color: ${(props) => {
    if (!props.loader) return '#151515';
    if (props.loader && props.mode) return '#7364E7';
  }};
  ${(props) => {
    if (!props?.mode && props.loader) {
      return css`
        background: linear-gradient(
          90deg,
          #250e49 0%,
          #121aa8 76.04%,
          #0852a5 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
      `;
    }
  }}
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ScrollBlock = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 396px;
  margin-right: -15px;
  padding-right: 15px;
  margin-top: -6px;
  @media ${device.tablet} {
    max-height: 300px;
    margin-top: 0;
  }
  @media ${device.mobile} {
    max-height: 100%;
    margin: 0;
    max-height: auto;
    padding-right: 0;
  }
`;
