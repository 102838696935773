import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import KycPage from '../pages/KycPage/KycPage';
import DevPage from '../pages/DevPage/DevPage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<Layout />}>
          <Route index element={<KycPage />} />
          <Route path={'dev'} element={<DevPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
