import React from 'react';
import {
  ActionButton,
  Blur,
  CenterIconBlock,
  DeleteButton,
  DropzoneWrapper,
  ErrorBlock,
  ErrorBlockContent,
  ImageBlock,
  LoaderBlock,
  SubTitle,
  SubTitleError,
  TargetBlock,
  Title,
  TittleError,
} from './DropzoneBlock.styled';
import { RemoveIcon } from '../Icons';
import Loader from '../Loader/Loader';

export const DropzoneBlock = ({
  mode,
  logoIcon,
  text,
  error,
  preview,
  loader,
  getRootProps,
  getInputProps,
  actionButtonClick,
  handleRemove,
  disableActionButton = false,
}) => {
  return (
    <DropzoneWrapper mode={mode} error={error}>
      <ActionButton
        onClick={actionButtonClick}
        disableActionButton={disableActionButton}
      />
      <input {...getInputProps()} />
      {preview ? (
        <ImageBlock>
          <img src={preview?.preview} alt='i' />
          <DeleteButton onClick={handleRemove}>
            <RemoveIcon />
          </DeleteButton>
        </ImageBlock>
      ) : (
        <TargetBlock {...getRootProps()}>
          <CenterIconBlock>{logoIcon}</CenterIconBlock>
          <Title mode={mode}>{text}</Title>
          <SubTitle mode={mode}>
            Перетащите фото сюда <br /> или выберите на <span>устройстве</span>
          </SubTitle>
        </TargetBlock>
      )}
      {loader ? (
        <LoaderBlock>
          <Loader />
        </LoaderBlock>
      ) : null}
      {error ? (
        <ErrorBlock>
          <Blur />
          <ErrorBlockContent>
            <TittleError mode={mode}>Ошибка!</TittleError>
            <SubTitleError>{error.message}</SubTitleError>
          </ErrorBlockContent>
        </ErrorBlock>
      ) : null}
    </DropzoneWrapper>
  );
};
